import { Component, Input, OnInit, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { ContratosNominaService } from '../../services/contratos.service';
import { ContratoNominaDTO } from '@src/app/models/Nomina/Contrato.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ProcessType, SPlusDialogSettings, SPlusFormsDialogsService, SPlusNotificationService, SPlusNotificationSettings, SPlusOption } from '@saludplus/forms';
import { IntlService } from '@progress/kendo-angular-intl';
import { ContratoNominaComponent } from '../../components/contratos.component';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { Empleados } from '@src/app/models/Nomina/Empleados.model';

@Component({
  selector: 'app-tabContratos',
  templateUrl: './TabContratos.component.html',
  styleUrls: ['./TabContratos.component.scss']
})
export class TabContratosComponent implements OnInit {
  @Input() idContrato: number;
  @Input() datosEmpleado: Empleados;
  @Input() processType: ProcessType;
  public ProcessType = ProcessType ;
  @ViewChild("container", { read: ViewContainerRef })
  public containerRef: ViewContainerRef;
  datosContrato: ContratoNominaDTO;
 

  options: SPlusOption[] = [];

  private sPlusFormsDialogsService = inject(SPlusFormsDialogsService);
  notificationService = inject(SPlusNotificationService);
  contratosService = inject(ContratosNominaService)
  dialogService = inject(DialogService)

  constructor(
  ) { }

  ngOnInit() {
    if (this.idContrato) {
      this.LoadRecord();

      this.options.push({
        id: "ir-al-contrato",
        icon: "fa-sharp fa-solid fa-share ",
        text: "Ir al contrato",
        link: "/nomina/contratos/" + this.idContrato,
      });
    }

  }

  LoadRecord(): void {
    this.contratosService.BuscarRegistro<ContratoNominaDTO>(this.idContrato).subscribe({
      next: (res) => {

        if (res) {
          this.datosContrato = res;
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar Contrato";
        dialogSettings.description =
          "Hubo un error al intentar cargar el contrato por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.sPlusFormsDialogsService.Show(dialogSettings);
      },
    });
  }
  public showConfirmation(): void {
    const dialogRef = this.dialogService.open({
      content: ContratoNominaComponent,
      title: "Crear Contrato",
      actions: [{ text: "Cancelar" }, { text: "Guardar", themeColor: "primary" }],
      appendTo: this.containerRef,
      width: "90%",
      height: "70%",	
      minWidth: "40%",
      minHeight: "50%",
      preventAction: (ev, dialog) => {
        if (ev instanceof DialogCloseResult || ev.text === "Cancelar") {
          return false;
        }
        const data = (dialog.content.instance as ContratoNominaComponent)
        const formGroup = data.FormRecord

        if (!formGroup.valid) {
          data.submitted = true;
          formGroup.markAsDirty();
          formGroup.updateValueAndValidity();
        }
        return !formGroup.valid;
      },
    });
    const userInfo = dialogRef.content.instance as ContratoNominaComponent;
    userInfo.loadSource = 'popup';
    userInfo.empleados.push(this.datosEmpleado);
    userInfo.datosEmpleado = this.datosEmpleado;

    dialogRef.result.subscribe(async (r: { [key: string]: string }) => {

      if (r['text'] === "Guardar") {
        await userInfo.SaveRecordInPopup();
        this.idContrato = userInfo.id;
        this.LoadRecord();

        this.options = [];
        this.options.push({
          id: "ir-al-contrato",
          icon: "fa-sharp fa-solid fa-share ",
          text: "Ir al contrato",
          link: "/nomina/contratos/" + this.idContrato,
        });

        this.presentToast("Se ha creado el contrato exitosamente", "success");
      }
    });
  }


  presentToast(message: string, type) {
    let notificationSettings = {} as SPlusNotificationSettings;
    notificationSettings.content = message;
    notificationSettings.type = type;
    notificationSettings.hideAfter = 5000;

    this.notificationService.Show(notificationSettings);
  }

}
