<splus-forms id="contratosNominaPage" [submit]="submitted" [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

  <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
    [ngClass]="{'formGroup-read': processType == ProcessType.view}">
    <splus-forms-header [isLoading]="isLoading" *ngIf="loadSource != 'tab'">
      
      <splus-forms-main-title *ngIf="loadSource != 'popup'" [title]="'Contratos'"
        [titleData]="processType == ProcessType.view ? '' : 'Contratos'" [icon]="icon">
      </splus-forms-main-title>

      <div *ngIf="processType == ProcessType.view || processType == ProcessType.edit">
        <div class="sub-title-info ">
            Número del contrato #{{ id }}

            <div *ngIf="isLoading">

                <div class="k-hbox">
                    <kendo-skeleton shape="text" animation="pulse" width="100px"></kendo-skeleton> &nbsp;
                    <kendo-skeleton shape="text" animation="pulse" width="70px"></kendo-skeleton> &nbsp;
                    <kendo-skeleton shape="text" animation="pulse" width="90px"></kendo-skeleton>
                </div>
            </div>


        </div>
    </div>

      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

          <button type="Submit" [disabled]="isLoading"
            *ngIf="loadSource != 'popup' && (processType == ProcessType.create || processType == ProcessType.edit)" kendoButton
            rounded="small" themeColor="primary">
            <i class="fa-solid fa-floppy-disk"></i> Guardar
          </button>
 

          <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
            rounded="small" themeColor="primary" (click)="goEdit()">
            <i class="fa-solid fa-pencil"></i> Editar
          </button>

          <button *ngIf="processType == ProcessType.edit && loadSource != 'popup'" type="button" kendoButton rounded="small"
            themeColor="base" (click)="cancelEdit()">
            Cancelar
          </button>

          <button *ngIf="processType == ProcessType.view && loadSource != 'popup'" type="button" kendoButton rounded="small"
            themeColor="base" (click)="goback()">
            Volver
          </button>

          <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small" themeColor="base"
            (click)="clearForm()">
            Limpiar
          </button>

        </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>


    </splus-forms-header>
    
    <splus-forms-fields [processType]="processType">


      <splus-forms-group [title]="'Datos Empleado'" #grupoEmpleado>

        <splus-field *ngVar="f.idEmpleado as ctrl" [splusFormControl]="ctrl">

          <kendo-combobox #selectEmpleado splusInput [formControl]="ctrl" [clearButton]="selectEmpleado.isOpen"
            [splusGroup]="grupoEmpleado" [filterable]="true" [data]="empleados" [valueField]="'id'"
            [textField]="'nombreCompleto'" [allowCustom]="false" placeholder="Escribe para buscar..."
            [id]="ctrl.nameControl" (filterChange)="Filtrar($event)">
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span>{{ dataItem.numeroDocumento + " - " + dataItem.nombreCompleto }}</span>
            </ng-template>
            <ng-template kendoDropDownListNoDataTemplate>
              <div>
                <i class="fa-sharp fa-regular fa-circle-exclamation"></i><br />
                No hay datos.<br />
              </div>
            </ng-template>

            <ng-template kendoPrefixTemplate [showSeparator]="true">
              <i title="empleado"
                class="pl-1 pr-1 fa-duotone fa-solid fa-user-tie-hair opacity-80 text-orange-500 text-base dark:text-orange-400 fa-lg"></i>
            </ng-template>

            <ng-template kendoSuffixTemplate [showSeparator]="true"
              *ngIf="processType !== ProcessType.view && !inPopUp">
              <button title="Buscar empleado" kendoButton type="button" style="padding: 0px 5px 0px 5px;"
                fillMode="flat" (click)="OpenDiagBuscarEMpleados(popBuscarEmpleados)"> <i
                  class="fa-duotone fa-arrow-up-right-from-square text-primary text-base dark:text-blue-500"></i>
              </button>
            </ng-template>

          </kendo-combobox>
          <ng-template splusFieldViewModeTemplate>
            {{selectEmpleado["text"]}}
          </ng-template>


        </splus-field>
        <splus-field *ngVar="f.idCargo as ctrl" [splusFormControl]="ctrl" #idCargo>
          <kendo-combobox #select splusInput [splusField]="idCargo" [splusGroup]="grupoEmpleado" [formControl]="ctrl"
            [data]="cargos" [textField]="'nombre'" [valueField]="'id'" [valuePrimitive]="true"
            (selectionChange)="CargarSalario($event)">
            <ng-template kendoComboBoxItemTemplate let-dataItem>
              <div>
                <div>{{dataItem.nombre}}</div>
                <div class="text-xs  text-gray-500">Salario Base: {{(dataItem.salario ? dataItem.salario : 0) |
                  currency:'$':'symbol':'1.0-0'}}</div>
              </div>
            </ng-template>



          </kendo-combobox>

          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

      </splus-forms-group>

      <splus-forms-group [title]="'Información del Contrato'" #grupoContratacion>

        <splus-field *ngVar="f.idTerminoContrato as ctrl" [splusFormControl]="ctrl">
          <div>
            <kendo-dropdownlist #select splusInput [splusGroup]="grupoContratacion" [formControl]="ctrl"
              [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField"
              (valueChange)="TerminoContratoChange($event)" [valuePrimitive]="true">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <splus-field *ngVar="f.idPeriodoPago as ctrl" [splusFormControl]="ctrl">
          <div>
            <kendo-dropdownlist #select splusInput [splusGroup]="grupoContratacion" [formControl]="ctrl"
              [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField"
              [valuePrimitive]="true">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>


        <splus-field *ngVar="f.fechaInicio as ctrl" [splusFormControl]="ctrl" #fecha>
          <kendo-datepicker #calendar splusInput [navigation]="true"  
            format="dd/MM/yyyy" [formControl]="ctrl" [splusField]="fecha" [value]="ctrl.value"
            [splusGroup]="grupoContratacion">
            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{ isNaN(title) ? title : " Ene. " + title.substring(2, 4) }}
            </ng-template>
          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{ calendar.value | SPlusDate }}
          </ng-template>
        </splus-field>
        <splus-field *ngVar="f.fechaFin as ctrl" [splusFormControl]="ctrl" #fecha>
          <kendo-datepicker #calendar splusInput [navigation]="true" [min]="minFinalDate" format="dd/MM/yyyy"
            [formControl]="ctrl" [splusField]="fecha" [value]="ctrl.value" [splusGroup]="grupoContratacion">
            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{ isNaN(title) ? title : " Ene. " + title.substring(2, 4) }}
            </ng-template>
          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{ calendar.value | SPlusDate }}
          </ng-template>
        </splus-field>


        <splus-field *ngVar="f.estado as ctrl" [splusFormControl]="ctrl">
          <div>
            <kendo-dropdownlist #select splusInput [splusGroup]="grupoContratacion" [formControl]="ctrl"
              [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField"
              [valuePrimitive]="true">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

      </splus-forms-group>
 
      <splus-forms-group [title]="'Información de Pago'" #grupoPago>
        <!-- Campo: Método de Pago -->
        <splus-field *ngVar="f.idMetodoPago as ctrl" [splusFormControl]="ctrl">
          <div>
            <kendo-dropdownlist #select splusInput [splusGroup]="grupoPago" [formControl]="ctrl"
              [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField" [data]="ctrl?.funtionData | async"
              [valuePrimitive]="true"  (valueChange)="MetodoPagoChange($event)">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <!-- Campo: Tipo de Cuenta -->
        <splus-field *ngVar="f.idTipoCuenta as ctrl" [splusFormControl]="ctrl">
          <div>
            <kendo-dropdownlist #select splusInput [splusGroup]="grupoPago" [formControl]="ctrl"
              [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField" [data]="ctrl?.funtionData | async"
              [valuePrimitive]="true">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <!-- Campo: Banco -->
        <splus-field *ngVar="f.idBanco as ctrl" [splusFormControl]="ctrl">
          <div>
            <kendo-dropdownlist #select splusInput [splusGroup]="grupoPago" [formControl]="ctrl"
              [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField" [data]="ctrl?.funtionData | async"
              [valuePrimitive]="true">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <splus-field *ngVar="f.numeroCuenta as ctrl" [splusFormControl]="ctrl">
          <kendo-textbox splusInput [splusGroup]="grupoPago" type="text" [id]="ctrl.nameControl" [formControl]="ctrl">
          </kendo-textbox>
        </splus-field>

      </splus-forms-group>




      <splus-forms-group [title]="'Remuneración'" #grupoRemuneracion>

        <splus-field *ngVar="f.idTipoSalario as ctrl" [splusFormControl]="ctrl">
          <div>
            <kendo-dropdownlist #select splusInput [splusGroup]="grupoRemuneracion" [formControl]="ctrl"
              [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField" [data]="ctrl?.funtionData | async"
              [valuePrimitive]="true">
            </kendo-dropdownlist>
          </div>
          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>

        <splus-field *ngVar="f.salario as ctrl" [splusFormControl]="ctrl">
          <kendo-numerictextbox splusInput [splusGroup]="grupoRemuneracion" [id]="ctrl.nameControl" [spinners]="false"
            [min]="0" [format]="{ currency: 'COP'  }" [formControl]="ctrl"></kendo-numerictextbox>

          <ng-template splusFieldViewModeTemplate>
            {{ ctrl?.value | currency:'$':'symbol':'1.0-0' }}
          </ng-template>
        </splus-field>


      </splus-forms-group>

<!--  
      <splus-tabs [keepTabContent]="true">
        <splus-tab [selected]="true"  >

          <ng-template splusTabTitle>
            <span class="select-none"> <i class=" text-nomina fa-duotone fa-solid fa-memo-pad mr-1"></i>
              Conceptos del contrato</span>
          </ng-template>

          <ng-template splusTabContent>
         
            <kendo-grid [kendoGridBinding]="gridDataReglasPorAplicar" [filterable]="true" [height]="250">

              <kendo-grid-column field="idNovedad" title="ID" [width]="80">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
                  </kendo-grid-string-filter-cell>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="text-center">
                    {{ dataItem.idNovedad }}
                  </div>
                </ng-template>
              </kendo-grid-column>
          
              <kendo-grid-column  title="Código" filter="text">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
                  </kendo-grid-string-filter-cell>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div>
                    {{ dataItem.llave }}
                  </div>
          
                  @if(dataItem.error){
                  <div class="text-xs text-red-500">
                    <i class="fa-light fa-circle-exclamation text-red-500"></i> {{ dataItem.errorMessage }}
                  </div>
                  }
                </ng-template>
              </kendo-grid-column>
          
          
              <kendo-grid-column field="nombreNovedad" title="Concepto" filter="text">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
                  </kendo-grid-string-filter-cell>
                </ng-template>
              </kendo-grid-column>
          
              <kendo-grid-column field="tipo" title="Tipo" filter="text">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
                  </kendo-grid-string-filter-cell>
                </ng-template>
              </kendo-grid-column>
          
              <kendo-grid-column field="opcional" title="Aplicar" filter="boolean" [width]="120">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <kendo-grid-boolean-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
                  </kendo-grid-boolean-filter-cell>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="text-center">
                    @if (dataItem.opcional == false) {
                    <i class="fa-duotone fa-solid fa-lock text-slate-500" title="Este concepto se aplica automáticamente"></i>
                    }@else {
                    <input type="checkbox" [checked]="dataItem.opcional" />
                    }
                  </div>
                </ng-template>
              </kendo-grid-column>
          
            </kendo-grid>

          </ng-template>
        </splus-tab>
      </splus-tabs>
-->
    </splus-forms-fields>

  </form>
</splus-forms>

<ng-template #popBuscarEmpleados class="fuente">
  <app-search-in-list [inPopup]="true" (SendDataEmitter)="GetSelectionEmpleado($event)" [setting]="ListadoEmpleado"
    [filtersData]="filtersEmpleado">
  </app-search-in-list>
</ng-template>
<ng-container #container *ngIf="!closePop"></ng-container>
 