import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, Inject } from "@angular/core";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  MultipleFilter,
  MultipleFilterData,
} from "@src/app/models/Tegett/SimpleFilterData";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods } from "@src/app/models/base/filterModel";
import { CargosDTO } from "@src/app/models/Nomina/Cargos";
import { ContratosNominaService } from "../services/contratos.service";
import { rangoFechasValidator } from "@src/app/components/Core/directives/generals/validatorError";

@Component({
  selector: "app-contratosNominaSettings",
  template: "",
})
export class ContratosSettings implements BaseSearchSetting {

  cargos: MultipleFilter;


  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(ContratosNominaService) private contratoServices: ContratosNominaService,
  ) {

  }
  async Load() {
    this.cargos = await this.Cargos();

    if (this.cargos) {
      return true;
    }
    else {
      return false;
    }


  }
  /**
   * Funcion para armar la busqueda
   */
  loadFormSearch(): FormSearch {
    const search = new FormSearch();
    search.formComponents = this.FormComponents();
    search.configSearch = {
      title: "Contratos",
      titleIcon: "fa-duotone fa-solid fa-memo-pad",
      apiSearch: ControllerApiList.Nomina.Contrato,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nuevo Contrato",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),

      settings: [
        { objectKey: "id", sortV3: "desc", visible: true },
      ],
      fields: [
        {
          renderLabel: "Número contrato",
          objectKey: "id",
          autoSize: true,
          module: "nomina",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),

        },
        {
          objectKey: "nombreEmpleado",
          renderLabel: "Empleado",
        },
        {
          objectKey: "numeroDocumento",
          renderLabel: "Número Identificación",
        },
        {
          objectKey: "cargo",
          renderLabel: "Cargo",
        },
        {
          objectKey: "tipoContratacion",
          renderLabel: "Tipo Contratación",
        },
        {
          objectKey: "fechaInicio",
          renderLabel: "Fecha Inicio",
        },
        {
          objectKey: "fechaInicio",
          renderLabel: "Fecha Fin",
        },

      ],
      filters: [
        { objectKey: "primerNombre", type: SearchTypeFilter.Text },
        { objectKey: "segundoNombre", type: SearchTypeFilter.Text },
        { objectKey: "primerApellido", type: SearchTypeFilter.Text },
        { objectKey: "segundoApellido", type: SearchTypeFilter.Text },
        {
          objectKey: "idCargo",
          type: SearchTypeFilter.MultipleFilter,
          data: this.cargos,
        },

      ],
    };
    return search;
  }




  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }



  async Cargos() {

    let filter = {} as MultipleFilter;
    filter.data = [];
    filter.label = "Cargos";
    filter.type = SearchTypeFilter.MultipleFilter;
    let dataResponse = await this.contratoServices.ObtenerCargosAsync<CargosDTO[]>();
    if (dataResponse) {
      dataResponse.forEach((elemt, index) => {
        let estado = {} as MultipleFilterData;

        estado.field = index;
        estado.text = elemt.nombre;
        estado.value = elemt.id;
        filter.data.push(estado);
      });
      return filter;
    }

    return null;

  }



  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {

        // IdEmpleado
        idEmpleado: new TegettFormControl({
          formState: null,
          validatorOrOpts: [Validators.required],
          asyncValidator: undefined,
          label: "Empleado",
          typeField: TypeField.List,
          required: true,
          nameControl: "idEmpleado"
        }),
        // idCargo
        idCargo: new TegettFormControl({
          formState: null,
          validatorOrOpts: [Validators.required],
          asyncValidator: undefined,
          label: "Cargo",
          typeField: TypeField.List,
          required: true,
          nameControl: "idCargo"
        }),

        // Tipo Contratacion
        idTerminoContrato: new TegettFormControl({
          label: "Tipo de contratación",
          typeField: TypeField.List,
          validatorOrOpts: [Validators.required],
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          nameControl: "idTipoContratacion",
          funtionData: this.contratoServices.ObtenerTiposContratacion()
        }),

        idPeriodoPago: new TegettFormControl({
          label: "Periodo de pago",
          typeField: TypeField.List,
          validatorOrOpts: [Validators.required],
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          nameControl: "idPeriodoPago",
          funtionData: this.contratoServices.ObtenerPeriodosPagos()
        }),

        // Salario
        salario: new TegettFormControl({
          formState: 0,
          label: "Salario",
          validatorOrOpts: [Validators.required],
          typeField: TypeField.Input,
          required: true,
          nameControl: "salario",
        }),


        fechaInicio: new TegettFormControl({
          label: "Inicio del contrato",
          validatorOrOpts: [Validators.required],
          required: true,
        }),

        fechaFin: new TegettFormControl({
          label: "Fin del contrato",
          required: false,
        }),

        estado: new TegettFormControl({
          label: "Estado del contrato",
          typeField: TypeField.List,
          validatorOrOpts: [Validators.required],
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          nameControl: "estado",
          funtionData: this.contratoServices.ObtenerEstadoContrato()
        }),



        idMetodoPago: new TegettFormControl({
          label: "Método de Pago",
          typeField: TypeField.List,
          validatorOrOpts: [Validators.required],
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          nameControl: "idMetodoPago",
          funtionData: this.contratoServices.ObtenerMetodosPago()
        }),

        idTipoCuenta: new TegettFormControl({
          label: "Tipo de Cuenta",
          typeField: TypeField.List,
          validatorOrOpts: [Validators.required],
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          nameControl: "idTipoCuenta",
          funtionData: this.contratoServices.ObtenerTiposCuenta()
        }),

        idBanco: new TegettFormControl({
          label: "Banco",
          typeField: TypeField.List,
          validatorOrOpts: [Validators.required],
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          nameControl: "idBanco",
          funtionData: this.contratoServices.ObtenerBancos()
        }),

        numeroCuenta: new TegettFormControl({
          validatorOrOpts: [Validators.required],
          label: "Número de cuenta",
          typeField: TypeField.Input,
          required: true,
          nameControl: "numeroCuenta",
        }),


        idTipoSalario: new TegettFormControl({
          label: "Tipo de salario",
          typeField: TypeField.List,
          validatorOrOpts: [Validators.required],
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          nameControl: "idTipoSalario",
          funtionData: this.contratoServices.ObtenerTipoSalario()
        }),


      },{validators:rangoFechasValidator('fechaInicio', 'fechaFin')}
    );

    return formRecord;
  }

  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
