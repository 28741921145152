<button type="button" *ngIf="!idContrato && processType == ProcessType.edit" kendoButton rounded="small"
  themeColor="primary" (click)="showConfirmation()">

  <i class="fa-regular fa-user-tie-hair"></i> Agregar Contrato
</button>
<div *ngIf="idContrato">

  <div class="bg-[#cccccc4a] m-0 mt-[-4px]">

    <splus-forms-toolbar [options]="options">
      <div class="pl-2 mt-2 " >  Contrato actual del empleado 

      </div>
    </splus-forms-toolbar>
  </div>
  <app-contratos [id]="idContrato" [loadSource]="'tab'" [processType]="ProcessType.view"></app-contratos>
</div>



<ng-container #container></ng-container>