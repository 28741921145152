import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component";
import { ContratoNominaComponent } from "./components/contratos.component";
import { ContratosSettings } from "./config/contratos.settings";
const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Contratos Listado" },
  },
  { path: "", component: ContratoNominaComponent },
  { path: ":id", component: ContratoNominaComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContratosRoutingModule {

  //Routing principal
  public static RoutingContratos = {
    path: "nomina/contratos",
    loadChildren: () =>
      import("./contratos.module").then(
        (m) => m.ContratosModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Contratos`,
      icon: "fa-duotone fa-solid fa-memo-pad",
      setting: ContratosSettings,
    },
  };
}
